.AdministerStock {
  .btn-on {
    background: #3640b4;
    color: #fff;
  }

  .table {
    thead {
      th {
        .mandatory-label {
          width: auto;
        }
      }
    }

    .fa-check-circle {
      width: 1.5rem;
      height: 1.5rem;
    }

    .amount {
      width: unset;
    }

    .CheckableInputs {
      justify-content: center;
      margin-top: 1px;

      li:last-child .checkable-label {
          padding-right: 0;
      }

      svg {
          font-size: 1rem;
      }

      .checked-yes svg {
          color: $success;
      }

      .compliant-no svg {
          color: $danger;
      }

      input {
          &:disabled + .checkable-box {
              background-color: unset;
          }
          &:disabled + .checkable-box svg {
              color: $dark-grey;
          }
      }
    }  
  }

  .administer-patient-table {
    margin-top: 1rem;
  }

  .stock-management-table {
    margin-top: 0;
  }

  .stocks-dropdown {
    max-width: 20rem;

    .dropdown-input-block {
      display: flex;
      align-items: center;

      svg {
        margin-right: 1em;
      }

      input {
        background: $light-grey;
        text-align: left;
        margin: 0;
        padding-left: 1em;
        cursor: pointer;
      }
    }

    .dropdown-menu {
      background: $light-grey;
      border-color: $gray-300;
      margin: 0;
      width: calc(100% - 1rem - 1em + #{$input-border-width * 2});

      ul {
        padding: 0;
        margin: 0;
        list-style: none;

        li {
          cursor: pointer;
          line-height: 2.4;
          padding-left: 1em;
          text-decoration: underline;

          &:hover {
            background: #000;
            color: #fff;
          }
        }
      }

      &.show {
        margin-left: 2em;
      }
    }
  }

    .LocationDropdown {
        cursor: pointer;
        width: 100%;

        .dropdown-menu {
            max-height: 10rem;
            overflow-y: auto;
            top: 100% !important;
            transform: none !important;
            width: 100%;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                box-shadow: inset 0 -1px $very-light-grey;
                cursor: pointer;
                padding: 0.5rem 1rem;

                &:first-child {
                    box-shadow: inset 0 -1px $very-light-grey, 0 -1px $very-light-grey;
                }

                &:hover {
                    background: $primary;
                    color: $white;
                }
            }

            svg {
                margin-right: 1rem;
            }
        }
    }

  .confirmation-block {
    margin-bottom: 1rem;
  }

  .stock-history {
    margin-top: 2rem;
    position: relative;

    h5 {
      border-bottom: 2px dotted $table-border-color;
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: $table-border-spacing-y;
      padding: 0 $table-cell-padding $table-cell-padding;
      width: 100%;
    }

    .history-btn {
      position: absolute;
      right: 0;
      top: -1rem;

      &:focus:not(:focus-visible) {
        box-shadow: unset;
      }
    }

    .table {
      margin-top: 0;
    }
  }
}
