.DiscrepancyModal {
    @include media-breakpoint-down(lg) {
        max-width: unset;
    }

    @include media-breakpoint-down(xs) {
        margin: 0.5rem 0;
    }

    @include media-breakpoint-up(sm) {
        width: calc(100% - #{$grid-gutter-width});
    }

    .stocks-dropdown.disabled {
        svg {
            display: none;
        }

        .form-control {
            text-align: left;
        }
    }
}
