.WardPicker {
    // max-width: 20rem;
    min-width: 15rem;
}

.search {
    &-holder {
        width: 100%;
        position: relative;
        z-index: 10;
    }

    &-input {
        border-radius: 50rem !important;
        border-width: 2px;
        border-color: $primary;

        &:focus {
            box-shadow: $input-focus-box-shadow !important;
        }
    }

    &-button {
        border-radius: 50rem;
        position: absolute;
        right: 0;
        background: transparent;
        color: $primary;
        border: 0;

        &:hover,
        &:active,
        &:focus {
            background: $primary;
            color: $white;
        }

        svg {
            margin-left: 0;
        }
    }

    &-area {
        &-holder {
            .error-icon {
                display: none;
                position: absolute;
                left: -2.5rem;
                top: 0.75rem;
                color: $danger;
            }

            input:-internal-autofill-selected {
                background-color: $primary;
                color: $white;
            }

            &-missing {
                border-color: $danger;
                .search-area-input { border-color: $danger; }
                .search-area-icon { color: $danger; }
                .error-icon { display: block; }
            }
        }

        &-input {
            background: transparent;
            font-weight: bold;
            color: $text-body;
            padding-left: 5px;
            border: none;
            border-bottom: 2px solid $primary;
            font-size: 1.15rem;
            &:hover { cursor: pointer;}
            &:focus {
                background: $primary;
                color: $white;
            }
        }

        &-icon {
            position: absolute;
            left: -1.5rem;
            top: 0.8rem;
            color: $primary;
        }

        &-list {
            position: absolute;
            top: 2.25rem;
            width: 100%;
            max-height: 50vh;
            overflow: scroll;
            filter: $dropshadow;
            z-index: 10;

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    background: $white;
                    padding: 0.5rem 1rem;
                    border-bottom: 1px solid $light-grey;
                    cursor: pointer;

                    &:hover {
                        background: $dark-grey;
                        color: $white;
                    }
                }
            }
        }
    }

    &-list {
        ul {
            list-style-type: none;
            padding: 0;
        }
    }

    &-list-fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
        z-index: 9;

        ul {
            width: 50%;
            margin: 18.5rem auto 0;
            padding: 0 0 1rem;
            list-style-type: none;
            max-height: calc(100vh - 18.5rem);
            overflow: scroll;

            li {
                background: $white;
                border-radius: 0.25rem;
                color: $text-body;
                margin-bottom: 0.5rem;
                user-select: none;

                &:hover {
                    background: $gray-300;
                }
            }
        }

        label {
            padding: 0.5rem 1rem;
        }
    }
}
