.table {
  th,
  td {
    &.wardinfo {
      text-align: left;
      @media (max-width: 480px) {
        display: none;
      }
    }
  }
}

.back-btn {
  margin-right: 1rem;
}

.mobile-only {
  display: none;
}
.location-type{
  white-space: pre-wrap;
}
@media (max-width: 480px) {
  .location {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .mobile-only {
    display: inline-block;
  }
  .desktop-only {
    display: none;
  }
}
