.IllicitDrugs {
    .table {
        margin-top: 1.5rem;
    }
}

.IllicitDrugs-pharmacy {
    .table {
        margin-bottom: 1rem - $table-border-spacing-y;
    }

    .table-link {
        align-items: center;
        display: flex;
        min-height: $input-height;
    }

    .c-switch {
        padding: unset;
        vertical-align: top;
    }

    .loader {
        margin: 4rem auto;
    }

    .page-message {
        margin-top: 3rem;
    }

    .form {
        margin-bottom: 0;

        .CheckableInputs {
            flex-basis: auto;
        }
    }
}

.IllicitDrugs-ward {
    .btn {
        text-transform: uppercase;

        &-on {
            background-color: $primary;
            color: $white;
        }
    }

    .status-text {
        display: block;
        padding: calc(#{$input-padding-y} + #{$input-border-width}) 0;
    }
}
