.dash-row {
    // justify-content: space-around;

    .dash-item {
        align-items: center;
        background: $white;
        border-radius: 0.5rem;
        display: flex;
        filter: $dropshadow;
        margin-top: 2rem;

        a {
            display: block;
            padding: 1rem 0;
            color: $text-body;
            text-decoration: none;
            width: 100%;
        }

        &-title {
            font-size: 1.25rem;
            font-weight: bold;
            margin-bottom: 0;
            height: 100%;
            display: flex;
            align-items: center;
        }

        &_block {
            display: flex;
            flex-wrap: wrap;

            &-title {
                flex: 1;

                .dash-item-title {
                    height: auto;
                }
            }
        }

        // Dash panel
        .dash-urgent {
            flex: 0.5;
            font-weight: bold;
            text-align: center;

            &-percentage {
                background: $light-grey;
                border-radius: 50rem;
                padding: 0.5rem;
                height: 5.5rem;
                width: 5.5rem;
                margin: 0 auto;
                margin-bottom: 0.75rem;
                position: relative;

                @media (max-width: 1199.98px) {
                    margin: 1.75rem auto 0.75rem;
                }
                &-half {
                    height: 2.5rem;
                    width: 2.5rem;
                }
            }

            &-colour {
                position: absolute;
                top: 0;
                left: 0;
            }

            &-bumper {
                background: $white;
                border-radius: 50rem;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.15rem;
                filter: $dropshadow;
                position: relative;
                z-index: 1;
            }
        }

        // Dash button
        .dash-icon {
            font-size: 1rem;
            color: $white;

            &-holder {
                border: 2px solid $border-25;
                border-radius: 1rem;
                display: flex;
                height: 3rem;
                width: 3rem;
                justify-content: center;
            }

            &-bumper {
                text-align: center;
                background: $black;
                border-radius: 50rem;
                height: 75%;
                width: 75%;
                display: flex;
                align-items: center;
                justify-content: center;
                align-self: center;
                filter: $dropshadow;
            }
        }
    }

    &-subcolumn{
        padding: 0;
    }
}

