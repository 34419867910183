.CloneApprovedList {
    .WardPicker {
        margin-left: 0.5rem;
        width: 22rem;
    }

    .search-area {
        &-input {
            font-size: 1.7rem;
        }

        &-icon {
            display: none;
        }

        &-list {
            top: 3.5rem;
            z-index: 11;
        }
    }

    .search-list-fixed ul {
        margin-top: 16rem;
    }
}
