// Colours
$bright-yellow-crayola: #fba823ff;
$persimmon: #f15b13ff;
$french-rose: #ef4889ff;
$fuchsia: #ec35ffff;
$french-violet: #882cc0ff;
$picotee-blue: #232281ff;
$medium-turquoise: #00cbbbff;
$light-purple: #B200FF;
$medium-sea-green: #46a867ff;

$very-light-grey: #f3f3f3;
$light-grey: $gray-200;
$dark-grey: #707070;
$icon-light-grey: #a9a9a9;

// Basic
$black: #212529;
$white-50: rgba(255, 255, 255, 0.5);
$white-70: rgba(255, 255, 255, 0.7);

$primary: #3640b4;
$danger: #d5281b;
$info: #4611b0;
$success: #007f3b;
$warning: #ec9b00;

$text-body: $black;

$border-25: rgba(31, 33, 37, 0.25);

$base-line-height: $font-size-base * $line-height-base;

$dropshadow: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2));

$content-box-background-color: $light-grey;
$content-box-border: 1px solid $gray-300;
$content-box-border-radius: 0.25rem;

$form-max-width: 1200px;
$form-label-width: 302px;
$form-control-invalid-focus-box-shadow: 0 0 0 0.2rem rgba(213, 40, 27, 0.25);

$btn-min-width: 2.5rem;
$dropdown-min-width: 3rem;
$input-min-width: 3rem;

$icon-height: 2.25rem;

$table-border-spacing-y: 0.375rem;
