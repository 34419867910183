.CurrentStock {
    &-search {
        margin-right: auto;
        width: 20rem;
    }

    .table {
        margin-top: 2rem;

        .NumberInput {
            color: unset;

            &:disabled {
                border: $input-border-width solid $input-border-color;
            }
        }

        .status-btn {
            border: unset;
            padding: unset;
            &:focus:not(:focus-visible) {
                box-shadow: unset;
            }
        }

        .status-icon {
            background-color: $danger;
            border-radius: 0.25rem;
            color: $white;
            height: $input-height;
            min-width: unset;
            width: $input-height;
        }

        .alert-theatre {
            min-width: 20px;
            max-width: 20px;
            padding-left: 0rem; 
            padding-right: 0rem
        }
        .alert-theatre-icon {
            color: $danger; 
            text-align: center;
            height: 1.1rem;
            width: 1.1rem;
            display: inline-block;
            width: 100%;
        }
    }

    td {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        width: 10rem;

        &:first-child {
            width: 40rem;
        }

        &:last-child {
            width: 2rem;
        }

        &.icons {
            min-width: calc(#{$input-height} + 1rem + 1px);
        }
    }

    .table-link {
        align-items: center;
        display: flex;
        min-height: $input-height;
    }

    .stock-focus {
        &:hover {
            cursor: auto;
            background: $white-50;
        }
    }

    tr.stock-active {
        background: $white-50;

        &:hover {
            background: $white-50;
        }
    }

    tr.stock-active {
        &.upper-line {
            td:first-child {
                border-bottom-left-radius: 0;
            }

            td:last-child {
                border-bottom-right-radius: 0;
            }
        }

        &.stock-focus {
            td:first-child {
                border-top-left-radius: 0;
            }

            td:last-child {
                border-top-right-radius: 0;
            }
        }
    }

    .stock-focus-message {
        background: $light-grey;
        position: relative;

        textarea {
            border: none;
            background: transparent;
            width: 100%;
        }

        textarea {
            margin-left: 1rem;
            padding-right: 1rem;
        }
    }

    .stock-message-hint {
        background: $black;
        color: $white;
        position: absolute;
        height: 100%;
        padding: 0.15rem;
        left: 0;
    }

    .form-check-input {
        position: relative;
        padding: 0;
        margin-left: 0 !important;
    }

    .action-btn {
        border: 2px inset transparent;
        &.active {
            border-color: $primary;
        }
    }

    .check-complete-btn {
        display: block;
        margin: 0 0 2rem auto;
    }
}
