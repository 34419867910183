html {
    font-family: Arial, Helvetica, sans-serif;
}

.container-fluid {
    @media (max-width: 480px) {
        padding-left: 0;
        padding-right: 0;
    }
}
.col {
    @media (max-width: 480px) {
        padding-left: 5px;
        padding-right: 5px;
    }
}

.c-wrapper {
    // min-height: 53rem;

    &.routes {
        margin-left: 200px !important;

        @media (max-width: 991.98px) {
            margin-left: 0 !important;
        }
    }
}

.modal {
    .modal-content {
        background-color: $body-bg;
        box-shadow: $box-shadow;
    }
}

.row.full-width-sm {
    @include media-breakpoint-down(sm) {
        > .col,
        > [class*="col-"] {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @include media-breakpoint-up(md) {
        > .col,
        > [class*="col-"] {
            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }
}

.header-breadcrumbs {
    display: flex;
    align-items: center;

    .breadcrumb {
        border-bottom: 0;
        margin-bottom: 0;

        .breadcrumb-item + .breadcrumb-item::before {
            content: "";
            padding-right: 0;
        }

        svg {
            color: $primary;
        }
    }
}

h1 {
    font-weight: bold;
}

svg {
    &.on-right {
        margin-left: 1rem;
    }

    &.on-left {
        margin-right: 1rem;
    }

    &.on-left-short {
        margin-right: 0.5rem;
    }
}

.btn {
    font-weight: bold;

    &-round {
        border-radius: 50rem;
        font-size: 1.35rem;
    }

    &.active {
        background: transparent;
        border: 1px solid;
        color: $primary;
    }

    &-black {
        background: $black;
        color: $white;

        &:hover {
            color: $white;
        }
    }

    &-blue {
        background: $picotee-blue;
        color: $white;

        &:hover {
            color: $white;
        }
    }

    &-green {
        background: $medium-turquoise;
        color: $white;

        &:hover {
            color: $white;
        }
    }

    &-icon {
        white-space: nowrap;
    }

    &:disabled {
        cursor: unset;
    }
}

.form-control {
    &.invalid {
        border-color: $danger !important;

        &:focus-visible {
            box-shadow: $form-control-invalid-focus-box-shadow;
        }
    }
}

.NumberInput {
    min-width: calc(var(--content-min-width) + #{$input-padding-x * 2} + #{$input-border-width * 2});
    width: calc(var(--content-width) + #{$input-padding-x * 2} + #{$input-border-width * 2});
}

.position-relative {
    position: relative;
}

.alert {
    position: fixed;
    top: 6rem;
    right: 2rem;
    z-index: $zindex-modal + 1;
    width: 40rem;
    font-size: 1rem;

    .close {
        padding: 0;
        display: flex;
        color: inherit;
        border: 1px solid;
        border-radius: 50rem;
        width: 1.25rem;
        height: 1.25rem;
        align-content: center;
        justify-content: center;
        font-size: 1rem;
        right: 0.5rem !important;
        top: 0.5rem;
    }

    &-icon_holder {
        display: inline-flex;
        width: 2.5rem;
        height: 2.5rem;
        border: 1px solid;
        border-radius: 50rem;
        font-size: 1rem;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;
    }
}

label {
    padding: 0.5rem;
    margin-bottom: 0;
    width: 100%;
    cursor: pointer;
    position: relative;

    .checkmark {
        position: absolute;
        top: 0;
        right: 10px;
        height: 100%;
        align-items: center;
        display: flex;

        .icon {
            font-size: 1.05rem;

            &.check {
                display: none;
                color: $success;
            }
        }
    }

    // &:hover input~.checkmark {}

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    input:checked ~ .checkmark {
        .icon.check { display: block; }
    }

    input:checked ~ .checkmark:after {
        display: block;
    }
}

.confirmButton {
    margin-bottom: 2.5rem;
}

.status-icon {
    border-radius: 50rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.25rem;
    width: 2.25rem;
    min-width: 2.25rem;
    margin: 0 auto;

    &.status-on {
        border: 2px solid $black;
        background: $black;
        color: $body-bg;
    }

    &.status-off {
        border: 3px double $icon-light-grey;
        color: $icon-light-grey;
    }

    &.status-out {
        border: 2px solid $light-purple;
        background: $light-purple;
        color: $body-bg;
    }
    &.status-check {
        border: 2px solid rgb(24, 168, 216);
        background: rgb(24, 168, 216);
        color: $body-bg;
    }
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: $dark-grey;
    border-color: $dark-grey;
}

.alert-input {
    border-color: $danger;
    outline-color: $danger;
}

.PageMissing {
    &-svgImage {
        display: flex;
        align-items: center;
        height: 100vh;
    }

    &-link {
        position: absolute;
        right: 0;
        bottom: 15vh;
        font-size: 1rem;

        h2 {
            font-weight: bold;
        }

        span {
            display: block;

            &:last-child {
                text-align: right;
            }
        }

        a {
            border-radius: 0.5rem;
            display: inline-block;
            margin-left: 1rem;
            background: $black;
            color: $white;
            font-weight: bold;
            padding: 1rem;
        }
    }
}

.comments-block {
    display: flex;
    margin-bottom: 2rem + $table-border-spacing-y;
    padding: 0;

    .icon-block {
        background-color: $black;
        border-bottom-left-radius: $content-box-border-radius;
        border-top-left-radius: $content-box-border-radius;
        width: 2rem;

        svg {
            display: block;
            margin: 0.7rem auto;
        }
    }

    .input-group {
        background-color: $content-box-background-color;
        border: $content-box-border;
        border-bottom-right-radius: $content-box-border-radius;
        border-left: 0;
        border-top-right-radius: $content-box-border-radius;
        padding: 1rem 1rem 1rem 0;

        .comments-sub {
            font-weight: bold;
            padding: calc(#{$input-padding-y} + #{$input-border-width}) 0.5rem;
        }

        textarea {
            border-color: $gray-300;
            border-radius: $content-box-border-radius !important;
            height: calc(#{$line-height-base} * 3em + #{$input-padding-y * 2} + #{$input-border-width * 2});
            resize: none;

            &:focus {
                border-color: $input-focus-border-color;
            }
        }
    }
}

.icon-delete {
    border-color: $input-border-color;
    min-width: $btn-min-width;

    &:focus:not(:focus-visible) {
        box-shadow: unset;
    }

    &:focus-visible {
        border-color: $input-focus-border-color;
    }

    &:hover {
        background-color: $black;
        border-color: $black;
        color: $white;
    }
}

.loader {
    display: block;
    margin: 0 auto;
    width: 2rem;

    [role="status"] {
        color: $icon-light-grey;
        vertical-align: top;
    }
}

.mandatory-label {
    margin-right: 0.3rem;
    color: $danger;
}

.page-header,
.view-buttons {
    .btn {
        text-transform: uppercase;

        &:not(:focus-visible),
        &:enabled:focus:not(:focus-visible) {
            box-shadow: $box-shadow-sm;
        }
    }
}

.page-header {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    .page-heading {
        margin-right: 0.5rem;
    }

    .btn {
        margin: 0 0 0.5rem auto;
    }
}

.view-buttons {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    > span {
        margin-bottom: 0.5rem;
    }

    .btn {
        margin: 0 0 0.5rem 0.5rem;
    }
}

.page-message {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 1rem auto 4rem;
    text-align: center;
}

.status-text {
    font-weight: bold;
    text-transform: uppercase;

    // @include media-breakpoint-up(lg) {
    //     white-space: nowrap;
    // }
}

.stock-check {
    border: 2px solid;
    border-radius: $content-box-border-radius;
    color: $primary;
    margin-bottom: 2rem;
    padding: 0.25rem 1rem;

    .created-by,
    dd {
        font-weight: bold;
    }

    p,
    dl {
        margin: 0;
    }

    dt,
    dd {
        display: unset;
    }

    dt {
        font-weight: unset;
        margin-right: 0.5em;
    }

    dd {
        margin: 0;
    }
}

.btn-icon sup {
    top: -1em
}

.btn-xs, .btn-group-xs > .btn {
    padding: 0.25rem 0.25rem;
    font-size: 0.78rem;
    line-height: 1;
    border-radius: 0.2rem;
    margin-right: 0.5rem;
}

.persist-linebreaks {
    white-space: pre-wrap;
}