.table {
  tr.stockCheck td:last-child {
      width: 4rem;
  }
}
  .stock-management-table {
    margin-top: 0;
  }

.stocks-dropdown {
  max-width: 20rem;

  .dropdown-input-block {
    display: flex;
    align-items: center;

    svg {
      margin-right: 1em;
    }

    input {
      background: $light-grey;
      text-align: left;
      margin: 0;
      padding-left: 1em;
      cursor: pointer;
    }
  }

  .dropdown-menu {
    background: $light-grey  !important;
    border-color: $gray-300;
    margin: 0;
    width: calc(100% - 1rem - 1em + #{$input-border-width * 2});

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        cursor: pointer;
        line-height: 2.4;
        padding-left: 1em;
        text-decoration: underline;

        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }

    &.show {
      margin-left: 2em;
    }
  }
}