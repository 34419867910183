.patient-picker {
    position: relative;

    .dropdown {
        &:not(.search) {
            width: 100%;
            position: absolute;
            z-index: 1;
            width: 14rem;
            left: 50%;
            display: none;
            transform: translateX(-50%) translateY(-2.5rem);
        }

        &.active {
            display: block;
        }

        .btn {
            border: 1px solid;
        }

        &.search {
            width: 35%;
            margin: 0 auto;

            .dropdown-input-block {
                display: flex;
                position: relative;

                .form-control {
                    border-radius: 50rem;
                    border-width: 2px;
                    border-color: $primary;

                    &:focus {
                        border-color: $input-focus-border-color;
                        box-shadow: $input-focus-box-shadow !important;
                    }

                    &.invalid {
                        border-color: $danger;

                        &:focus {
                            box-shadow: $form-control-invalid-focus-box-shadow !important;
                        }
                    }
                }

                .search-button {
                    top: 0;
                    border: none;
                }
            }
        }
    }

    .dropdown-menu {
        width: 100%;
        max-height: 28vh;
        min-height: 22.5rem; // Minimum height required for Create Patient form
        overflow-y: scroll;
        transform: translateY(2.2rem) !important;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
        }

        li {
            box-shadow: inset 0 -1px $very-light-grey;
            padding: 0.5rem 1rem;

            &:first-child {
                box-shadow: inset 0 -1px $very-light-grey, 0 -1px $very-light-grey;
            }

            svg {
            }
        }

        .dropdown-item {
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
                background: $primary;
                box-shadow: 0 1px $primary;
                color: $white;
            }
        }

        .dropdown-empty-item {
            color: $dark-grey;
        }

        form {
            padding: 0.5rem 1rem;
        }

        .form-group:not(:last-of-type) {
            margin: unset;
        }

        label {
            padding-bottom: 0.125rem;
            padding-left: 0;
        }

        textarea {
            height: calc(#{$line-height-base} * 2em + #{$input-padding-y * 2} + #{$input-border-width * 2});
            resize: none;
        }

        .btn-create {
            width: 100%;
        }

        .loader [role="status"] {
            color: $white;
            vertical-align: text-bottom;
        }
    }

    .form-control:disabled {
        background-color: $input-bg;
        opacity: $btn-disabled-opacity;
    }
}

.table td.patient-picker input {
    padding: $input-padding-y $input-padding-x;
    text-align: left;
}
