$quantity-input-spacing-x: 0.5rem;
$quantity-units-max-width: 10.5em;

.QuantityInput {
    align-items: center;
    display: flex;
    justify-content: center;

    &.align-center,
    &.align-end,
    &.align-start {
        .dropdown,
        .quantity-units {
            flex-grow: 1;
        }

        .quantity-units {
            text-align: left;
        }
    }

    &.align-end {
        justify-content: flex-end;
    }

    &.align-start {
        justify-content: flex-start;
    }

    .NumberInput {
        margin: 0;
        min-width: calc(var(--content-min-width) + #{$quantity-input-spacing-x * 2} + #{$input-border-width * 2});
        padding-left: $quantity-input-spacing-x;
        padding-right: $quantity-input-spacing-x;
        text-align: right;
        width: calc(var(--content-width) + #{$quantity-input-spacing-x * 2} + #{$input-border-width * 2});
    }

    .dropdown,
    .quantity-units {
        flex-shrink: 0;
    }

    .dropdown {
        margin-left: $quantity-input-spacing-x;
        max-width: calc(
            #{$quantity-units-max-width} +
            #{$caret-spacing + $caret-width * 2} +
            #{$quantity-input-spacing-x * 2} +
            #{$input-border-width * 2}
        );
    }

    .dropdown-toggle {
        align-items: center;
        border-color: $input-border-color;
        color: unset;
        display: flex;
        font-weight: unset;
        justify-content: space-between;
        min-width: $dropdown-min-width;
        padding-left: $quantity-input-spacing-x;
        padding-right: $quantity-input-spacing-x;

        &:not(:focus-visible) {
            box-shadow: inset 0 1px $white, 0 1px $white;
        }

        &:focus-visible {
            border-color: $input-focus-border-color;
        }

        &:disabled {
            box-shadow: unset;

            &::after {
                opacity: 0;
            }
        }

        &::after {
            transition: opacity 250ms;
        }

        span {
            max-width: $quantity-units-max-width;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .dropdown-menu {
        box-shadow: $box-shadow;
        min-width: 100%;
    }

    .dropdown-item {
        &:focus {
            outline: 0;
        }

        &.active {
            cursor: unset;
        }
    }

    .quantity-units {
        margin-left: $quantity-input-spacing-x;
    }
}

.quantity-units {
    display: inline-block;
    margin-left: 0.5em;
    max-width: $quantity-units-max-width;
    overflow: hidden;
    padding: unset;
    text-overflow: ellipsis;
    vertical-align: bottom;
    white-space: nowrap;
    width: unset;

    &:not([for]) {
        cursor: unset;
    }
}
