.TransferCD {
  .search-holder,
  .LocationDropdown {
    margin-bottom: 2rem;
    margin-top: 3rem;
  }
  .dropdown-menu {
    width: 100%;
    overflow-y: auto;
    max-height: 10rem;
    top: 0.6rem !important;
  }

  ul {
    list-style-type: none;
    padding: 0;
    svg {
      display: inline-block;
      margin-right: 1rem;
    }
    li {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1rem;
      border-bottom: 1px solid #d8dbe0;
    }
  }
  .TransferOrder_header_qty {
    text-align: right;
  }
  .TransferOrder_line {
    .TransferOrder_line_qty {
      justify-content: right;
    }
    .TransferOrder_line_uom {
      text-align: left;
    }
    td {
      border-left: 0;
    }
  }

  .icon {
    &-urgent
    {
      border-radius: 0.5rem;
      width: 2.5rem;
    }

    &-urgent {
      color: $warning;
      border-color: $warning;

      &-active,
      &:hover {
        background: $warning;
        color: $white;
      }
    }

    &-urgent:focus {
      box-shadow: 0 0 0.2rem rgba(236, 155, 0, 0.25);
    }
  }
}