.AddIllicitDrug {
    h2 {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .loader {
        margin: 4rem auto;
    }
}

.AddIllicitDrug-pharmacy {
    .form-control[readonly] {
        background-color: unset;
        border: unset;
        opacity: unset;
    }
}
.AddIllicitDrug-Input{
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.AddIllicitDrug-ward {
    .btn {
        text-transform: uppercase;
    }

    .form {
        position: relative;
        transition: opacity 500ms;

        &.loading .form-group {
            opacity: 0.25;
            pointer-events: none;
        }

        .loader {
            left: calc(50% - 1rem);
            position: absolute;
            top: calc(50% - 1rem);
        }
    }

    .tabs-length-2 .nav-item {
        width: 50%;
    }

    .nav-link {
        border: 2px solid;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        color: $primary;
        cursor: pointer;
        text-align: center;

        &:hover {
            border-color: $primary;
            color: $primary;
        }

        &.active {
            background-color: $primary;
            border-color: $primary;
            color: $white;
        }

        h3 {
            font-size: 1rem;
            margin: 0;
        }
    }

    .tab-content {
        background-color: $content-box-background-color;
        border: $content-box-border;
        border-bottom-left-radius: $content-box-border-radius;
        border-bottom-right-radius: $content-box-border-radius;
        border-top: 0;
        padding: 1rem 2rem;
        margin-bottom: 2rem;
        min-height: 8rem;

        h4 {
            font-weight: bold;
        }

        .confirmation-block {
            border: 0;
            margin-bottom: 0;
            padding: 0;
        }
    }

    .stock-details {
        margin: 0;

        dt,
        dd {
            display: inline;
        }

        dt {
            margin-right: 0.5em;
        }

        dd {
            margin: 0;
        }
    }
}
