.StockTableRow {
    td:last-child {
        width: 4rem;
    }

    .form-control:not(.NumberInput) {
        padding: $input-padding-y $input-padding-x;
        text-align: left;
    }
}
