$balance-calculator-input-padding-x: 0.5rem;
$balance-calculator-spacing-x: $btn-border-radius;

.BalanceCalculator {
    display: inline-flex;
    flex-wrap: unset;
    width: unset;

    .btn {
        border-color: $input-border-color;
        min-width: $btn-min-width - $btn-border-radius;

        &:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &:focus {
            border-color: $input-focus-border-color;
        }

        &:not(:disabled) {
            &:hover {
                background-color: $black;
                border-color: $black;
                color: $white;
            }
        }

        &:disabled {
            cursor: unset;
        }

        &.hidden {
            opacity: 0;
        }
    }

    .form-control {
        border-radius: $input-border-radius !important;
        margin: 0 $balance-calculator-spacing-x;
        min-width: $input-min-width;
        padding-left: $balance-calculator-input-padding-x;
        padding-right: $balance-calculator-input-padding-x;
        width: calc(var(--content-max-width) + #{$balance-calculator-input-padding-x * 2} + #{$input-border-width * 2});
    }
}
