.Disposal {
    .register-table {
        margin-top: 0;

        tr {
            &.type-cd {
                td:first-child {
                    border-color: $warning;
                }
            }

            &.type-clinical {
                td:first-child {
                    border-color: $light-purple;
                }
            }

            &.type-illicit {
                .reference {
                    color: $primary;
                }
            }
        }

        th {
            width: 7rem;
            &:first-child {
                width: auto;
            }
            &.quantity-unit {
                min-width: 8em;
            }
        }

        td {
            &.quantity-unit {
                text-align: left;
            }
        }

        .drug-name {
            align-items: center;
            display: flex;
            justify-content: space-between;
        }

        .quantity {
            align-items: center;
            border-radius: 50rem;
            border: 1px solid;
            color: $black;
            display: inline-flex;
            height: $icon-height;
            justify-content: center;
            min-width: $icon-height;
            padding: 0 0.5em;
            white-space: nowrap;
        }

        .c-switch {
            margin: calc((#{$icon-height} - #{$switch-height}) / 2) 0;
            padding: unset;
            vertical-align: top;
        }

        .CheckableInputs {
            justify-content: center;
            margin-top: 1px;
    
            li:last-child .checkable-label {
                padding-right: 0;
            }
    
            svg {
                font-size: 1rem;
            }
    
            .checked-yes svg {
                color: $success;
            }
    
            .compliant-no svg {
                color: $danger;
            }
    
            input {
                &:disabled + .checkable-box {
                    background-color: unset;
                }
                &:disabled + .checkable-box svg {
                    color: $dark-grey;
                }
            }
        }

        .comments {
            margin-left: auto;
        }
    }

    .history-heading {
        border-bottom: $table-border-width * 2 dotted $table-border-color;
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: $table-border-spacing-y;
        padding: 0 $table-cell-padding $table-cell-padding;
        width: 100%;
    }

    .history-table {
        margin: 0 0 4rem;
    }

    .loader {
        margin: 4rem auto;
    }

    .page-message {
        margin-top: 3rem;
    }
}
