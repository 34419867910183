$report-link-height: 5rem;
$report-link-width: 20rem;
$report-link-spacing: 6.25rem;

.Reports {
    h2 {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .report-links-container {
        @include media-breakpoint-down(sm) {
            flex-basis: $report-link-width;
        }

        @include media-breakpoint-up(md) {
            flex-basis: $report-link-width * 2 + $report-link-spacing;
        }

        @include media-breakpoint-down(md) {
            margin: 0 auto 2rem;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 2rem;
        }
    }

    .report-links {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        margin: 0 -2rem -2rem 0;
        padding: 0;

        li {
            flex-basis: $report-link-width;
            margin: 0 2rem 2rem 0;
        }
    }

    .report-link {
        align-items: center;
        background-color: $white;
        border-radius: 0.5rem;
        color: unset;
        display: flex;
        filter: $dropshadow;
        font-size: 1.25rem;
        font-weight: bold;
        height: $report-link-height;
        padding: 0 1rem;

        &:hover {
            text-decoration: unset;
        }
    }
}

.report-page {
    .form {
        margin-bottom: 1rem;
    }

    .DrugSearchList {
        width: 100%;
    }

    .search-list-fixed {
        background: unset;
        height: unset;
        position: absolute;
        top: 100%;

        &::before {
            background-color: rgba(0, 0, 0, 0.75);
            bottom: 0;
            content: "";
            left: 0;
            position: fixed;
            right: 0;
            top: 0;
        }

        ul {
            margin: unset;
            padding: unset;
            position: relative;
            width: unset;

            @include media-breakpoint-down(sm) {
                max-height: calc(100vh - 20.5rem);
            }
        }
    }

    .report-table {
        margin-top: 0;
    }

    .loader {
        margin: 3rem auto;
    }
}

.CDOrders,
.GeneralStock {
    .form [class*="col-"]:first-child .form-label {
        flex-basis: 7.2em;
    }
}

.WardBalances {
    .total-balance {
        font-weight: bold;
        font-size: 1rem;
        padding: 0.25rem 0;
    }

    .report-table {
        font-size: unset;

        td,
        th {
            padding: 0.25rem 1rem;
        }

        td {
            vertical-align: middle;

            &.min-height::before {
                align-items: center;
            }
        }
    }
}
.complianceAudit{
        &-check-circle{ color: $success;
            margin-right: 1rem;}
        &-fa-check-cross{color: $danger; 
            margin-right: 1rem; }
        &-comment {
            background: $light-grey;
            color: $text-body;
            padding: 0.15rem 0.5rem;
            width: 90%;
            word-break: break-word;
            
        }
        &-button{
            font-Size: 2rem;
            overflow: hidden; 
            align-self: center;
            &-okStatus{
                background-color:$success!important;
                border-color:$success!important;
            }
            &-warningStatus{
                background-color:$warning!important;
                border-color:$warning!important;
            }
            &-alertStatus{
                background-color:$danger!important;
                border-color:$danger!important;
            }
        }

        &-receipt {
            background: $light-grey;
            // even
            &_item:nth-child(odd) { padding: 0.5rem 2rem 0.5rem 2rem;  position: static;}
            // odd
            &_item:nth-child(even) { padding: 0.5rem 2rem 0.5rem 2rem;  position: static;}
    
            &_item .row {
                flex-wrap: nowrap;
            }
    
            &_item-info { display: inline-block; width: 90%; }
    
            svg {
                margin-top: 1px;
            }
    
            .fa-comment-alt {
                margin-top: 2.2rem;
            }
        }
        &-fixedWidth{
            width: 2.5rem;
        }
}

.stockCheck {
    &-check-circle {
        color: $success;
        margin-right: 1rem;
    }

    &-fa-check-cross {
        color: $danger;
        margin-right: 1rem;
    }

    &-comment {
        background: $light-grey;
        color: $text-body;
        padding: 0.15rem 0.5rem;
        width: 90%;
        word-break: break-word;
    }

    &-button {
        font-Size: 2rem;
        overflow: hidden;
        align-self: center;
    }

    &-drug {
        background: $light-grey;
        // even
        &_item:nth-child(odd) {
            padding: 0.5rem 2rem 0.5rem 2rem;
            position: static;
        }
        // odd
        &_item:nth-child(even) {
            padding: 0.5rem 2rem 0.5rem 2rem;
            position: static;
        }

        &_item .row {
            flex-wrap: nowrap;
        }

        &_item-info {
            display: inline-block;
            width: 90%;
        }

        svg {
            margin-top: 1px;
        }

        .fa-comment-alt {
            margin-top: 2.2rem;
        }
    }

    &-fixedWidth {
        width: 2.5rem;
    }
}
