.table {
    margin-bottom: 2rem;
    margin-top: 3rem;
    border-collapse: inherit;
    border-spacing: 0 6px;

    .form-control:disabled,
    .form-control[readonly] {
        background: transparent;
        border: none;
        box-shadow: unset;
        color: inherit;
    }

    thead {
        font-size: 1rem;

        th {
            border-bottom-style: dotted;
            text-align: center;
            padding-top: 0;

            &:first-child {
                text-align: left;
            }
        }

        span {
            display: block;
            font-size: 12px;
            font-weight: lighter;
        }
    }

    tbody tr {
        background: none;
        border-radius: 0.25rem;
        box-shadow: $box-shadow-sm;
    }

    tbody td {
        &:first-child {
            border-bottom-left-radius: 0.25rem;
            border-top-left-radius: 0.25rem;
        }

        &:last-child {
            border-bottom-right-radius: 0.25rem;
            border-top-right-radius: 0.25rem;
        }
    }

    tr.active:hover {
        background: $white-50;
    }

    td,
    th {
        border-top: none;
        border-left: 1px dotted $light-grey;

        &:first-child {
            border-left: none;
            min-width: 10rem;
        }
    }

    td {
        background: $white-50;
        text-align: center;
        vertical-align: middle;
        padding: 0.3rem 0.5rem;

        &:first-child {
            border-left: 3px solid $primary;
            text-align: left;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        input {
            margin: 0 auto;
            text-align: center;
            padding: 0.25rem;

            &.nsvcode {
                max-width: 6rem;

                &[readonly] {
                    border: none;
                    background: none !important;
                }
            }

            &.threshold {
                max-width: 3rem;

                &[readonly] {
                    border: none;
                    background: none !important;
                }
            }
        }

        .NumberInput {
            max-width: calc(10ch + #{$input-padding-x * 2} + #{$input-border-width * 2});
        }
    }

    tr.drugline-error {
        background: rgba(250, 221, 221, 0.25) !important;

        td:first-child {
            border-left-color: $danger !important;
        }

        input {
            border-color: $danger;
        }
    }

    .spacer {
        background: none;

        &:hover {
            background: none;
        }

        td {
            height: 6px;
            padding: 0;
            background: transparent;

            &:first-child {
                border-color: transparent !important;
            }
        }
    }

    .table-link {
        display: block;
    }

    .btn-delete {
        padding: 0.25rem;
        display: flex;
        align-content: center;
        justify-content: center;
        background: transparent;
        font-size: 1.25rem;
        border: none;

        &:hover {
            background: $black;
            color: $white;
        }

        svg {
            margin: 0;
        }
    }

    .comments {
        background-color: $gray-100;
        border: $input-border-width solid $input-border-color;
        border-radius: $input-border-radius;
        flex-shrink: 0;
        margin-left: 0.5rem;
        max-height: calc(#{$base-line-height * 5} + 0.5rem + #{$input-border-width * 2});
        max-width: 50%;
        min-width: 5rem;
        overflow-wrap: anywhere;
        overflow-y: auto;
        padding: 0.25rem 0.5rem;
    }

    .invalid-feedback {
        color: $danger;
        display: block;
        left: 0;
        position: absolute;
        top: 100%;
    }

    .notes-row {
        box-shadow: none;

        td {
            background: unset;
            border: unset;
            padding-bottom: unset;
            padding-top: unset;
        }
    }
}

$table-font-size-md: 0.75rem;
$table-font-size-sm: 0.5rem;

.history-table,
.report-table {
    border-collapse: collapse;

    @include media-breakpoint-down(sm) {
        font-size: $table-font-size-sm;
    }

    @include media-breakpoint-between(md, lg) {
        font-size: $table-font-size-md;
    }

    td,
    th {
        &:first-child {
            min-width: unset;
        }
    }

    td {
        background: unset;
        border-left: unset;
        vertical-align: top;

        &:first-child {
            border-left: unset;
            text-align: center;
        }
    }

    thead {
        font-size: unset;
        position: sticky;
        top: 0;

        th {
            border: unset;

            &:first-child {
                text-align: center;
            }
        }
    }

    tbody {
        tr {
            box-shadow: unset;
        }

        td {
            &:first-child,
            &:last-child {
                border-radius: unset;
            }
        }
    }

    abbr {
        text-decoration: unset;
    }

    .break-words {
        overflow-wrap: anywhere;
    }

    .bold {
        font-weight: bold;
    }

    .no-wrap {
        white-space: nowrap;
    }
}

$report-table-head-bg: hsl(220, 50%, 50%);
$report-table-body-bg-even: $white;
$report-table-body-bg-odd: hsl(220, 40%, 95%);
$report-table-border-width: 2px;
$report-table-cell-padding: 0.25rem;

.report-table {
    border: $report-table-border-width solid $report-table-head-bg;

    tr {
        border-bottom: $report-table-border-width solid $report-table-head-bg;
    }

    td,
    th {
        padding: $report-table-cell-padding;

        &.min-height::before {
            align-items: flex-start;
            content: "\200b";
            display: inline-flex;

            @include media-breakpoint-down(sm) {
                min-height: $table-font-size-sm * 2 * $line-height-base;
            }

            @include media-breakpoint-between(md, lg) {
                min-height: $table-font-size-md * 2 * $line-height-base;
            }

            @include media-breakpoint-up(xl) {
                min-height: $font-size-base * 2 * $line-height-base;
            }
        }
    }

    td:first-child {
        padding-left: $report-table-cell-padding;
        padding-right: $report-table-cell-padding;
    }

    thead {
        background-color: $report-table-head-bg;
        color: $white;

        th {
            padding-top: $report-table-cell-padding;
        }
    }

    tbody {
        color: $black;

        tr {
            &:nth-child(odd) {
                background-color: $report-table-body-bg-odd;
            }

            &:nth-child(even) {
                background-color: $report-table-body-bg-even;
            }
        }
    }
}

$history-table-head-bg: rgb(165, 165, 165);
$history-table-body-bg: $light-grey;
$history-table-head-bg-administration: hsl(210, 60%, 60%);
$history-table-body-bg-administration: hsl(210, 60%, 85%);
$history-table-head-bg-management: hsl(95, 40%, 50%);
$history-table-body-bg-management: hsl(95, 40%, 85%);
$history-table-cell-padding-top: 1px;
$history-table-cell-padding-align-x: 0.75rem;
$history-table-cell-padding: $history-table-cell-padding-top 0.25rem 2px;
$history-table-cell-padding-xl: $history-table-cell-padding-top 0.5rem 2px;

.history-table {
    background-color: $history-table-body-bg;

    td,
    td:first-child,
    th {
        @include media-breakpoint-down(lg) {
            padding: $history-table-cell-padding;
        }

        @include media-breakpoint-up(xl) {
            padding: $history-table-cell-padding-xl;
        }
    }

    thead {
        background-color: $history-table-head-bg;
        color: $white;

        tr:nth-child(2) th {
            font-weight: unset;
        }

        th {
            padding-top: $history-table-cell-padding-top;
        }

        .administration {
            background-color: $history-table-head-bg-administration;
        }

        .management {
            background-color: $history-table-head-bg-management;
        }
    }

    tbody {
        color: $black;

        tr:not(.group-body) {
            border-top: $table-border-spacing-y solid $body-bg;
        }
    }

    .administration {
        background-color: $history-table-body-bg-administration;
    }

    .management {
        background-color: $history-table-body-bg-management;
    }

    .align-left {
        padding-left: $history-table-cell-padding-align-x;
        text-align: left;
    }

    .align-right {
        padding-right: $history-table-cell-padding-align-x;
        text-align: right;
    }

    .quantity {
        white-space: nowrap;
    }
}
