.CreateApprovedList {
    .search-area-input {
        font-size: 1.7rem;
    }
    .WardPicker {
        width: 22rem;
    }

    .search-area-list {
        top: 3.5rem;
        z-index: 11;
    }

    .search-area-input {
        font-size: 1.7rem;
        
        &:focus {
            background: $primary;
            color: $white;
        }
    }
    
}