.confirmation-block {
    background-color: $content-box-background-color;
    border: $content-box-border;
    border-radius: $content-box-border-radius;
    margin-bottom: 2rem;
    padding: 1rem;

    h4 {
        font-weight: bold;
    }

    p {
        margin-bottom: 0;
    }

    .form-control:disabled {
        background-color: $input-bg;
        opacity: $btn-disabled-opacity;
    }

    .sign-buttons {
        .row {
            align-items: center;
            flex-wrap: nowrap;
            justify-content: flex-end;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }

        label {
            cursor: unset;
            font-weight: bold;
            padding-bottom: 0;
            padding-left: 0;
            padding-top: 0;
            text-align: right;
            width: unset;
        }

        button {
            min-width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.sign-holder {
    text-align: right;
    justify-content: center;
    margin-bottom: 0.75rem;
    border: 1px solid transparent;
    border-radius: 30rem;
    padding: 0.25rem;
    width: 100%;

    &.alert-error {
        .alert-icon {
            position: absolute;
            top: 20%;
            font-size: 1rem;
            color: $danger;
        }
    }

    &.confirmed {
        border-color: $black;
    }

    label {
        white-space: nowrap;
    }

    input {
        text-align: left !important;
        padding: 0.375rem 0.75rem !important;
    }
}

.sign-button {
    background: none;
    display: flex;
    justify-content: center;
    align-content: center;
    border: 2px solid $white;
    border-radius: 30rem;
    padding-left: 2.5rem;
    padding-right: 1.5rem;
    position: relative;

    &:hover {
        background: $success;
        color: $white;
    }

    .sign-check-off {
        position: absolute;
        left: -2px;
        top: -1px;
        padding: 0.25rem;
        border-radius: 50rem;
        border: 2px solid $white;
        background: $success;
        color: $white;
        display: flex;
        align-items: center;

        svg {
            font-size: 1.5rem;
            margin: 0 auto;
        }
    }

    &.disabled {
        background: $black;
        color: $white;
        opacity: 1;
        border-color: $black;

        .sign-check-off {
            border-color: $black;
            color: $black;
        }
    }
}
