@charset "utf-8";

// Import styles
@import "@coreui/coreui/scss/coreui";

// 3rd party imports
@import "react-confirm-alert/src/react-confirm-alert.css";

// Site styles
@import "./variables";

@import "./scss/base";

@import "./scss/molecules/balancecalculator";
@import "./scss/molecules/checkableInputs";
@import "./scss/molecules/patientpicker";
@import "./scss/molecules/progressbar";
@import "./scss/molecules/quantityInput";
@import "./scss/molecules/search";
@import "./scss/molecules/signInput";
@import "./scss/molecules/simpleDropdown";
@import "./scss/molecules/stockTableRow";

@import "./scss/organisms/confirmAlert";
@import "./scss/organisms/discrepancyModal";
@import "./scss/organisms/drugSearchList";
@import "./scss/organisms/footer";
@import "./scss/organisms/forms";
@import "./scss/organisms/header";
@import "./scss/organisms/location";
@import "./scss/organisms/messages";
@import "./scss/organisms/paginationBlock";
@import "./scss/organisms/sidebar";
@import "./scss/organisms/tables";

@import "./scss/pages/addClinicalWaste";
@import "./scss/pages/addIllicitDrug";
@import "./scss/pages/addPatientsOwnStock";
@import "./scss/pages/addStock";
@import "./scss/pages/administerStock";
@import "./scss/pages/addReturnedCD";
@import "./scss/pages/allOrders";
@import "./scss/pages/cloneapprovedlist";
@import "./scss/pages/createapprovedlist";
@import "./scss/pages/createorder";
@import "./scss/pages/currentstock";
@import "./scss/pages/dashboard";
@import "./scss/pages/disposal";
@import "./scss/pages/illicitDrugs";
@import "./scss/pages/loginUser";
@import "./scss/pages/patientsOwnStock";
@import "./scss/pages/reports";
@import "./scss/pages/stockAudit";
@import "./scss/pages/systemAdmin";
@import "./scss/pages/formulary";
@import "./scss/pages/manageLocations";
@import "./scss/pages/patientModal";
@import "./scss/pages/transfercd";
