// Overrides for the default react-conform-alert styles

.react-confirm-alert-body {
    border: solid 1px $primary;
}

.react-confirm-alert-button-group {
	justify-content: end ;
}

.react-confirm-alert-button-group > button {
	background: $primary ;
}