.StockAudit,
.StockAuditClone {
    &-list { position: relative; }

    .table {
        margin-top: 0;

        th {
            width: 10rem;
            text-align: left;
            &:first-child {
                width: auto;
            }
        }

        tr {
            td:first-child {
                border-left: 0.025rem solid $light-grey;
            }
            td:last-child {
                border-right: 1px solid $light-grey;
            }
        }

        td {
            font-size: 1rem;
            border-top: 1px solid $light-grey;
            border-bottom: 1px solid $light-grey;

            div {
                display: flex;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
            }

            svg {
                color: $black;
            }

            span {
                display: block;
            }

            .stockAudit-line_todo,
            .stockAudit-line_done {
                width: 1.5rem;
                height: 1.5rem;
                border: 1px solid;
                border-radius: 0.3rem;
                font-family: 'Yesteryear', cursive;
                color: $black;
                position: relative;
                overflow: hidden;
                padding: 0;
                text-align: center;
                background: $white;

                &:hover {
                    background: $light-grey;
                    border-color: $black;
                }

                input {
                    position: relative;
                    height: auto;
                    width: auto;
                    margin-left: 0;
                    opacity: 0;
                    z-index: 2;
                 }

                span {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    z-index: 1;
                    width: 100%;
                }

                &.stockAudit-line_done {
                    border: none;
                    background: none;

                    span {
                        opacity: 1;
                    }
                }
            }
            .stockAudit-line_comment-button {
                text-align: center;
                background: none;
                border: none;
            }
        }
    }

    svg {
        font-size: 1.25rem;

        &.stockAudit-line_icon-comment,
        &.stockAudit-line_icon-info {
            margin-right: 1rem;
            margin-top: 0.1rem;
            color: $black;
        }
    }

    .StockAudit-commentblock {
        background: #f5f6f7;
        border: 0.025rem solid $light-grey;
        position: absolute;
        top: 4.5rem;
        width: 100%;
        padding: 1rem;
        font-size: 1rem;
        z-index: 2;

        &.hide {
            display: none;
        }

        textarea {
            padding: 0.5rem;
            width: 95%;
        }
    }
}

.StockAudit {
    counter-reset: audit-num;

    .table th.multi-line {
        text-align: center;

        em {
            display: inline-block;
            font-style: unset;
            margin: 0 0.75rem;
            width: 1.5rem;
        }
    }

    .stockAudit-line {
        td {
            vertical-align: top;
        }

        &_icon-info {
            margin-top: 2px !important;
        }

        &_name::before {
            counter-increment: audit-num;
            content: counter(audit-num) ". ";
        }

        .CheckableInputs {
            justify-content: center;
            margin-top: 1px;

            li:last-child .checkable-label {
                padding-right: 0;
            }

            svg {
                font-size: 1rem;
            }

            .compliant-yes svg {
                color: $success;
            }

            .compliant-no svg {
                color: $danger;
            }
        }

        &_comment-block {
            display: block !important;

            label {
                font-weight: bold;
                padding-bottom: 0;
                padding-top: 0;
            }

            textarea {
                height: calc(#{$line-height-base} * 3em + #{$input-padding-y * 2} + #{$input-border-width * 2});
                margin-bottom: 0.5rem;
                resize: none;
            }
        }

        &_confirm-button {
            float: right;
            margin-bottom: 0.2rem;

            svg {
                color: inherit !important;
                vertical-align: top;
            }
        }

        &_comment-button {
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;

            svg {
                vertical-align: top;
            }
        }

        &_icon-comment {
            margin: 4px 1rem 0 0 !important;
        }

        &_icon-edit {
            margin: 1px 0 3px;
        }
    }

    .confirmButton svg {
        vertical-align: top;
    }
}

.StockAuditReview {
    .fa-check-circle { color: $success; }

    h4 {
        display: block;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 0;
    }

    &-comment {
        background: $white;
        color: $text-body;
        padding: 0.15rem 0.5rem;
        width: 90%;
        word-break: break-word;
    }

    &-receipt {
        background: $light-grey;
        // even
        &_item:nth-child(odd) { padding: 0.5rem 2rem 0.5rem 2rem; }
        // odd
        &_item:nth-child(even) { padding: 0.5rem 2rem 0.5rem 2rem; }

        &_item .row {
            flex-wrap: nowrap;
        }

        &_item-info { display: inline-block; width: 90%; }

        svg {
            margin-top: 1px;
        }

        .fa-comment-alt {
            margin-top: 5px;
        }
    }

    .confirmation-block {
        background: none;

        .fa-check-circle { color: $white; }
    }
}

.CreateNewAuditLine,
.StockAuditCreate,
.StockAuditIndividual,
.StockAuditClone {
    &-list {
        ul {
            list-style-type: none;
            margin-bottom: 2rem;
            padding: 0;

            @include media-breakpoint-up(lg) {
                width: 75%;
            }
        }

        li {
            display: flex;
            justify-content: space-between;
            background: $white-50;
            align-items: center;
            padding: 0.75rem 0.5rem;
            margin-bottom: 0.5rem;

            span { width: 90%; }

            &:hover {
                background: $light-grey;
            }

            .input-group {
                input {
                    padding-left: 5rem;
                }

                textarea {
                    padding-left: 7rem;
                    resize: none;
                }
            }
        }

        .btn-delete {
            padding: 0.25rem;
            display: flex;
            align-content: center;
            justify-content: center;
            background: transparent;
            font-size: 1.25rem;
            border: none;

            &:hover {
                background: $black;
                color: $white;
            }
        }
    }

    .search-area-input {
        font-size: 1.7rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
}

.CreateNewAuditLine {
    h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .form {
        @include media-breakpoint-up(lg) {
            width: 75%;
        }
    }
}

.StockAuditCreate {
    h1 {
        display: inline-block;
        margin-right: 0.5rem;
    }

    .WardPicker {
        display: inline-block;
        margin-left: 2rem;
        min-width: 20.6rem;
    }
}
