.c-footer {
    align-items: unset;
    border-top: unset;
    justify-content: flex-end;
    margin: unset;
    padding: unset;
}

.footer-content {
    background: $dark-grey;
    color: $white;
    border-top-left-radius: 1rem;
    max-width: 50rem;

    .row {
        height: 100%;
        align-items: center;
    }

    .col,
    [class*="col-"] {
        &:not(:first-child):not(:last-child) {
            text-align: center;
        }

        &:last-child {
            text-align: right;
        }
    }
}
