.form {
  margin-bottom: 2rem;
  max-width: $form-max-width;

  .btn,
  .form-group {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .form-group {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }

    .btn {
      margin-bottom: 0;
    }
  }

  .checkable-label,
  .form-label,
  .legend {
    font-weight: bold;
  }

  .checkable-text,
  .form-label,
  .legend {
    &.required::before {
      color: $danger;
      content: "*";
      font-size: 0.75rem;
      margin-right: 0.2em;
      vertical-align: top;
    }
  }

  .form-label {
    flex-shrink: 0;
    padding: calc(#{$input-padding-y} + #{$input-border-width}) 0.5rem
      calc(#{$input-padding-y} + #{$input-border-width}) 0;

    @include media-breakpoint-up(lg) {
      flex-basis: $form-label-width;
      text-align: right;
    }

    &.disabled,
    &.readonly,
    &:not([for]) {
      cursor: unset;
    }
  }

  .btn-primary {
    box-shadow: $box-shadow-sm;
  }

  .form-control,
  .dropdown-toggle {
    border-radius: 0.5rem;

    @include media-breakpoint-up(lg) {
      flex-grow: 1;
      width: unset;
    }
  }

  .form-control {
    color: unset;
    font-size: unset;

    &:disabled {
      background-color: $input-bg;
      opacity: $btn-disabled-opacity;
    }

    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }
  }

  .dropdown {
    flex-grow: 1;
    width: 100%;
  }

  .dropdown-toggle {
    align-items: center;
    background-color: $input-bg;
    border-color: $input-border-color;
    color: unset;
    display: flex;
    font-weight: unset;
    justify-content: space-between;
    min-width: $dropdown-min-width;

    &:focus {
      border-color: $input-focus-border-color;
    }

    &:disabled::after {
      opacity: 0;
    }

    &::after {
      font-size: 1.4rem;
      margin-right: 0.35rem;
      transition: opacity 250ms;
    }
  }

  .dropdown-menu {
    box-shadow: $box-shadow-sm;
    min-width: 100%;
  }

  .dropdown-menu-buttons {
    display: flex;

    .btn {
      color: $primary;
      font-size: unset;
    }
  }

  .dropdown-item {
    justify-content: space-between;

    &:focus {
      outline: unset;
    }

    &.multiple {
      box-shadow: inset 0 -1px $white;

      &:focus {
        background-color: unset;
      }

      &:focus-visible,
      &:hover {
        background-color: $dropdown-link-hover-bg;
      }

      &:active,
      &.active {
        background-color: $primary-100;
        color: $black;
      }

      &.active {
        &:focus-visible,
        &:hover {
          background-color: $primary-200;
        }

        &:active {
          background-color: $primary-100;
        }
      }
    }

    svg {
      color: $primary;
    }
  }

  .search-input {
    border: $input-border-width solid $input-border-color;
    border-radius: 0.5rem !important;
    box-shadow: none !important;
    padding-right: 3rem;

    &:focus {
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow !important;
    }

    &:disabled .search-button {
      opacity: 0;
    }
  }

  .search-button {
    background-color: unset !important;
    border: $btn-border-width solid transparent !important;
    color: unset;
    opacity: unset;
    pointer-events: none;
    right: 0.35rem;
    transition: opacity 250ms;
    width: unset !important;
    z-index: 3;
  }

  textarea {
    height: calc(
      #{$line-height-base} * 3em + #{$input-padding-y * 2} + #{$input-border-width *
        2}
    );
    resize: none;
  }

  .input-value {
    margin: 0;
    padding: calc(#{$input-padding-y} + #{$input-border-width}) 0;
  }

  &.align-right {
    margin-left: auto;

    @include media-breakpoint-up(lg) {
      flex-basis: 100%;

      .form-group {
        justify-content: flex-end;
      }

      .form-label {
        flex: 0 0 auto;
        width: unset;
      }

      fieldset,
      .form-control {
        flex: 0 0 25rem;
      }
    }
  }

  &.horizontal {
    .form-label {
      flex: 0 0 auto;
      width: auto;
    }

    .btn {
      width: 100%;
    }

    .buttons {
      align-self: flex-end;
    }
  }

  &.show-validity {
    :invalid,
    .invalid {
      border-color: $danger;

      &:focus {
        box-shadow: $form-control-invalid-focus-box-shadow;
      }

      &.search-input:focus {
        box-shadow: $form-control-invalid-focus-box-shadow !important;
      }
    }

    .CheckableInputs :invalid {
      + .checkable-box {
        border-color: $danger;
      }

      &:focus-visible + .checkable-box {
        box-shadow: $form-control-invalid-focus-box-shadow;
      }
    }
  }

  .icon-block {
    background-color: $black;
    border-bottom-right-radius: $content-box-border-radius;
    border-top-right-radius: $content-box-border-radius;
    width: 2rem;

    svg {
      display: block;
      margin: 0.7rem auto;
    }
  }
}
