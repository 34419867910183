$dropdown-item-max-width: 10.5em;

.SimpleDropdown {
    align-items: center;
    display: flex;
    justify-content: center;

    &.align-center,
    &.align-end,
    &.align-start {
        .dropdown{
            flex-grow: 1;
        }
    }

    &.align-end {
        justify-content: flex-end;
    }

    &.align-start {
        justify-content: flex-start;
    }

    .dropdown {
        flex-shrink: 0;
        max-width: calc(
            #{$dropdown-item-max-width} +
            #{$caret-spacing + $caret-width * 2}
        );
    }

    .dropdown-toggle {
        align-items: center;
        background-color: $white;
        border-color: $input-border-color;
        color: $text-body;
        display: flex;
        font-weight: unset;
        justify-content: space-between;
        min-width: $dropdown-min-width;

        &:not(:focus) {
            box-shadow: inset 0 1px $white, 0 1px $white;
        }

        &:focus {
            border-color: $input-focus-border-color;
        }

        &:disabled {
            box-shadow: unset;
            background-color: unset;

            &::after {
                opacity: 0;
            }
        }

        &::after {
            transition: opacity 250ms;
        }

        span {
            max-width: $dropdown-item-max-width;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .dropdown-menu {
        box-shadow: $box-shadow-sm;
        min-width: 100%;
    }

    .dropdown-item {
        &:focus {
            outline: 0;
        }

        &.active {
            cursor: unset;
        }
    }
}