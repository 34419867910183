.pagination-wrapper {
  height: 34px;
  margin-top: 1.75rem;

  .pagination-nav {
    bottom: 2.25rem;
    position: absolute;
    right: 0;
  }

  .pagination {
    margin-bottom: 0;

    .page-item {
      margin-right: 1rem;

      .page-link {
        border-width: 2px;
        border-color: #3640b4;
        height: 34px;
        width: 34px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;

        &.arrow {
          border-radius: 50rem;

          span {
              background: #000;
              width: 20px;
              height: 20px;
              border-radius: 50rem;
              color: #fff;
              font-size: 2rem;
              text-align: center;
              line-height: 0.4;
          }
        }
      }
    }
  }
}
