.LoginUser {
    padding: 2rem;
    height: 100vh;
    .alert {
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
    }
    h1 {
        .logo {
            width: 10rem;
            margin: 0 auto;
            display: block;
        }
    }
    .form-group {
        position: relative;
    }
    label {
        width: auto;
        z-index: 1;
        padding: 0;
        &.nf-location {
            width: 5rem;
        }
    }
    .form-text {
        margin-left: 1rem;
    }
    input {
        height: calc(1.5em + 0.75rem + 3px);
        display: inline;
    }
    .dropdown {
        width: 100%;
    }
    .dropdown-menu {
        width: 100%;
        overflow-y: auto;
        max-height: 10rem;
        top: 0.6rem !important;
    }
    ul {
        list-style-type: none;
        padding: 0;
        li {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            padding-left: 1rem;
            border-bottom: 1px solid $light-grey;
            &:hover {
                background: $dark-grey;
                color: $white;
                cursor: pointer;
            }
        }
        svg {
            display: inline-block;
            margin-right: 1rem;
        }
    }

    .btn {
        margin-top: 1rem;
        margin-left: auto;
        margin-right: auto;
        display: block;
        width: 100%;
        padding: 1rem;
    }

    legend {
        font-size: unset;
        font-weight: bold;
        text-align: center;
    }

    .btn-auth-user {
        margin-bottom: 2rem;
        opacity: unset;
    }
}
