.CreateOrder,
.CreateOrderNamedPatient {
   .DrugSearchList {
        margin-top: 2rem;
    }

    .icon {
        &-urgent,
        &-patient,
        &-delete {
            border-radius: 0.5rem;
            width: 2.5rem;
        }

        &-urgent {
            color: $warning;
            border-color: $warning;

            &-active,
            &:hover {
                background: $warning;
                color: $white;
            }
        }

        &-patient {
            color: $primary;
            border-color: $primary;

            &-active,
            &:hover {
                background: $primary;
                color: $white;
            }
        }

        &-urgent:focus {
            box-shadow: 0 0 0.2rem rgba(236, 155, 0, 0.25);
        }

        &-patient:focus {
            box-shadow: 0 0 0.2rem rgba(54, 64, 180, 0.25);
        }

        &-delete {
            color: $white;
            background: $black;
        }
    }

    .table td {
        width: 3rem;

        &:nth-child(-n+2) {
            width: auto;
        }

        .LocationDropdown {
            cursor: pointer;
            width: 100%;
    
            .dropdown-menu {
                max-height: 10rem;
                overflow-y: auto;
                top: 100% !important;
                transform: none !important;
                width: 100%;
            }
    
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
    
                li {
                    box-shadow: inset 0 -1px $very-light-grey;
                    cursor: pointer;
                    padding: 0.5rem 1rem;
    
                    &:first-child {
                        box-shadow: inset 0 -1px $very-light-grey, 0 -1px $very-light-grey;
                    }
    
                    &:hover {
                        background: $primary;
                        color: $white;
                    }
                }
    
                svg {
                    margin-right: 1rem;
                }
            }
        }        
    }

    .table td,
    .table th {
        border-left: none;

        .running-balance {
            width: 7rem;
        }

        .prescription-history-btn {
            font-size: 1rem;
            padding: 0.25rem 0.5rem;            
            vertical-align: middle;
        }

        &.prescription-history-trigger {
            min-width: 0.25rem !important;
            width: 0.25rem !important;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        &.drug-name {
            text-align: left;
        }
        
        &.location-container {
            width: auto;
        }

    }

    .table  {
        .order-status {  
            &-red{
                td:first-child {
                    border-color : $danger;
                }
    
                .order-status {
                    background: rgba(246, 74, 132, 0.10);
                    color: $danger;
                }

                .NumberInput {
                    border: 1px solid $danger;
                    border-radius: 0.5rem;
                }
    
                .c-switch .c-switch-input:checked+.c-switch-slider {
                    background-color: $danger;
                }
            }
        }        
    }

    .search-list {
        max-height: 33rem;
        overflow-y: scroll;

        li {
            border: 1px solid $primary;
            margin-bottom: 0.5rem;
            border-radius: 0.25rem;
            background: $white-50;

            li {
                border: none;
                background: none;
                margin-bottom: 0;
            }
        }

        span {
            display: flex;
            margin-bottom: 0.5rem;

            &.name {
                font-weight: bold;
            }

            strong {
                margin-right: 0.5rem;
            }
        }
    }
}

.prescription-stock-history {
    padding-top: 2rem;
    margin-top: 2rem;
    position: relative;

    h5 {
        border-bottom: 2px dotted $table-border-color;
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: $table-border-spacing-y;
        padding: 0 $table-cell-padding $table-cell-padding;
        width: 100%;
    }

    .history-btn {
        position: absolute;
        right: 0;
        top:  1rem;

        &:focus:not(:focus-visible) {
        box-shadow: unset;
        }
    }

    table {
        margin-top: 0;

        th,
        td {
        &:nth-child(-n+2) {
            width: 1rem !important;
        }           
        }
    }
    }

.CreateOrder {
    .search-holder {
        margin-bottom: 2rem;
        margin-top: 3rem;
    }
}
