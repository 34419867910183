table.DrugSearchList {
  td.drugline {
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: left;
  }
}

.DrugSearchList {
  &.selectableSearch {
    display: flex;

    .search-holder {
      margin-right: 1rem;

      @include media-breakpoint-up(md) {
        flex-shrink: 0;
      }
    }

    .dropdown {
      @include media-breakpoint-up(md) {
        min-width: 12rem;
      }
    }
  }

  .dropdown-toggle {
    align-items: center;
    background-color: $input-bg;
    border-color: $input-border-color;
    color: unset;
    display: flex;
    font-weight: unset;
    justify-content: space-between;
    min-width: 100%;

    &:focus:not(:focus-visible) {
      box-shadow: unset;
    }

    &:focus-visible {
      border-color: $input-focus-border-color;
    }

    &:disabled::after {
      opacity: 0;
    }

    &::after {
      font-size: 1.4rem;
      margin-right: 0.35rem;
      transition: opacity 250ms;
    }
  }

  .dropdown-menu {
    box-shadow: $box-shadow-sm;
    min-width: 100%;
  }

  .dropdown-item {
    &:focus {
      outline: unset;
    }
  }
}
