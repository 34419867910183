.PatientsOwnStock {
    .table {
        tr.stockCheck td:last-child {
            width: 4rem;
        }

        td.icons {
            min-width: calc(#{$input-height} + 1rem + 1px);
        }

        .table-link {
            align-items: center;
            display: flex;
            min-height: $input-height;
        }

        .NumberInput {
            color: unset;

            &:disabled {
                border: $input-border-width solid $input-border-color;
            }
        }

        .status-icon {
            background-color: $danger;
            border-radius: 0.25rem;
            color: $white;
            height: $input-height;
            min-width: unset;
            width: $input-height;
        }
    }

    .check-complete-btn {
        display: block;
        margin: 0 0 2rem auto;
    }
}

.bagReference-label {
    width: auto;
    float: left;
}

.bagReference-input {
    width: 20rem;
    float: left;
    margin-left: 1rem
}

.check-patient-complete-btn {
    display: block;
    margin-right: 0.5rem;
}
