.order-messages {
    padding-bottom: 1rem;

    &_holder {
        max-height: 20rem;
        overflow-y: auto;
        position: relative;
        border-bottom: 3px solid $icon-light-grey;
    }

    &-item {
        padding: 0 0.5rem 1rem;
        position: relative;
    }

    &_ident {
        position: absolute;
        font-size: 0.7rem;
    }

    &_stamp {
        display: flex;
        align-items: center;
    }

    &_time {
        display: inline-block;
        margin-right: 0.5rem;
    }

    &_name {
        display: inline-block;
        font-weight: bold;
    }

    .order-messages_content {
        background: $light-grey;
        padding: 0.5rem 0.75rem 0.25rem;

        p {
            word-break: break-all;
        }
    }

    &_more {
        position: absolute;
        transform: translateX(50%);
        border: 1px solid;
        border-radius: 50rem;
        height: 2rem;
        width: 2rem;
        background: $primary;
        bottom: 0rem;
        color: $white;
        svg {
            font-size: 1rem;
        };

        &_left {
            @extend .order-messages_more;
            right : 45%;
        }

        &_right {
            @extend .order-messages_more;
            right : 55%;
        }

        &_double {
            @extend .order-messages_more;
            svg {
                &:last-child {
                    margin-top: -0.5rem;
                }
            };

            &_farleft {
                @extend .order-messages_more_double;
                right: 10%
            }

            &_farright {
                @extend .order-messages_more_double;
                right: 90%
            }
        }
    }

    &_location-default,
     &_location-left {
        .order-messages_stamp {
            padding-left: 1.5rem;
        }

        .order-messages_ident {
            left: 0.65rem;
        }

        .order-messages_content {
            border-top-right-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
        }
    }
    
    &_location-left {
        .order-messages_ident {
            color: $persimmon;
        }

        .order-messages_content {
            border-left: 1rem solid $persimmon;
        }
    }

    &_location-default {
        .order-messages_ident {
            color: $primary;
        }

        .order-messages_content {
            border-left: 1rem solid $primary;
        }
    }

    &_location-right {
        .order-messages_stamp {
            padding-right: 1.25rem;
            justify-content: flex-end;
        }

        .order-messages_ident {
            right: 0.65rem;
            color: $medium-sea-green;
        }

        .order-messages_content {
            border-right: 1rem solid $medium-sea-green;
            border-top-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
        }
    }
}