.c-header {
    background: none;
    border-bottom: none;
    justify-content: flex-end;

    .search-area-icon {
        top: 0.25rem;
        left: 0;
    }

    .WardPicker {
        min-width: auto;
        width: 14rem;
    }

    input {
        background: $light-grey;
        height: 1.5rem;
        border-bottom: 1px solid $primary;
        border-radius: 0;
        opacity: 1;
        color: $text-body;
        padding-left: 1rem;
        font-size: 1rem;
        line-height: 0;
        &:hover {
            cursor: pointer;
        }
    }

    span { position: relative; }

    .dropdown {
        width: 12rem;

        ul {
            list-style-type: none;
            padding: 0;
        }

        li {
            padding: 0.25rem 0.25rem 0.25rem 1.75rem;
            position: relative;
            border-bottom: 1px dotted $light-grey;
            &:hover {
                background: $dark-grey;
                color: white;
            }
        }

        svg {
            display: inline-block;
            position: absolute;
            left: 0.5rem;
            top: 0.5rem;
        }

        .dropdown-menu {
            top: -1.25rem !important;
            max-height: 12rem;
            overflow-y: scroll;
        }
    }

    .header{
        &-breadcrumbs {
            display: none;
        }

        &-bullet {
            background-color: $content-box-background-color;
            border-bottom: $content-box-border;
            border-left: $content-box-border;
            border-bottom-left-radius: 1rem;

            @include media-breakpoint-down(md) {
                max-width: calc(100% - 3.15rem - 60px);
            }

            @include media-breakpoint-down(sm) {
                padding: 0.5rem 1rem;
            }

            @include media-breakpoint-up(md) {
                padding: 0.75rem 1rem;
            }

            @include media-breakpoint-up(lg) {
                max-width: 50rem;
                min-width: 45rem;
            }
        }

        &-left,
        &-right {
            @include media-breakpoint-down(md) {
                padding-right: 2rem;

                .row {
                    justify-content: flex-end;
                }
            }
        }

        &-left {
            padding-left: 2rem;
        }

        &-right {
            padding-left: 2rem;
        }

        &-time {
            justify-content: center;
            align-items: center;
            display: flex;
            font-weight: bold;
            height: 100%;
            font-size: 1.5rem;
        }

        &-user_location {
            // border-bottom: 2px dotted;
        }

        &-user_name {
            font-size: 1.25rem;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-location_icon {
            position: absolute;
            z-index: 1;
            top: 0.26rem;
        }
    }

    .user-halo {
        border: 0.75rem solid $primary;
        border-radius: 50rem;
        margin-top: -1rem;
        margin-bottom: -2rem;
        height: 6rem;
        width: 6rem;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-left: -1rem;
        svg {
            color: $primary;
            font-size: 2.75rem;
        }
    }
}
