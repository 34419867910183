.AddPatientsOwnStock {
    .search-row {
        margin-top: 3rem;
    }

    .patient-picker {
        margin-bottom: 1rem;

        .dropdown.search {
            width: 100%;

            &.hidden {
                display: none;
            }
        }

        .patient {
            font-size: 1rem;
            margin: 0;

            .btn {
                color: $primary;
                font-size: inherit;
                font-weight: inherit;
                height: $input-height;
                margin-left: -$btn-padding-x;
                vertical-align: unset;
            }
        }
    }

    .drug-search-note {
        font-size: 0.8rem;
        margin: 0.25rem (-$grid-gutter-width) 0 1rem;
    }

    .table {
        margin-bottom: 4rem;
        margin-top: 2rem;
    }
}
