.SystemAdmin {
    &-list_all {
        max-height: 63vh;
        overflow: scroll;
    }

    &-location,
    &-role,
    &-staff {
        align-items: center;
        background: $white;
        border-left: 8px solid;
        border-top: 0;
        border-bottom: 0;
        border-radius: 1rem;
        color: $text-body;
        display: flex;
        font-size: 1rem;
        font-weight: bold;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 1rem;
        text-align: left;
        width: 100%;

        &:hover {
            box-shadow: none !important;
            color: $white;
        }
    }

    &-location {
        border-color: $bright-yellow-crayola;

        &:hover {
            background-color: $bright-yellow-crayola;
            border-color: $bright-yellow-crayola;
        }
    }

    &-staff {
        span {
            display: block;
        }

        &_name {
            font-weight: bold;
        }

        &_member {
            border: 0;
            margin: 1rem 0 0;
            background: $white;
            border-radius: 0.25rem;
            padding: 0.5rem;
            width: 100%;
            text-align: left;
            font-weight: inherit;

            &:hover {
                color: $white;
                background: $dark-grey;
            }

            span {
                font-size: 1rem;
                display: block;

                &:nth-child(2) {
                    margin-bottom: 0.5rem;
                    margin-top: 0.5rem;
                }
            }
        }
    }

    &-detail {
        padding-left: 3rem;
        max-width: 38rem;
        margin-top: -3.75rem;
    }
}

.SystemNewPerson {
    $form-max-width: 648px;

    &-info {
        display: flex;

        &_number {
            align-items: center;
            display: inline-flex;
            justify-content: center;
            background: $black;
            font-weight: bold;
            color: $white;
            border-radius: 50rem;
            width: 1.75rem;
            height: 1.75rem;
            margin-right: 1rem;
        }
    }

    &-detail_block {
        margin-top: 2rem;
        max-width: $form-max-width;
        // border: 1px solid $dark-grey;
        // padding: 1rem;
        // border-radius: 0.5rem;

        .SystemNewPerson-detail_location {
            resize: none;
            // height: 10rem;
            // padding-left: 10.5rem;
        }
    }
}

.SystemAdmin,
.SystemNewPerson {
    h2,
    h3,
    h4 {
        font-weight: bold;
        margin-bottom: 0;
    }

    &-detail_sign {
        margin: 0.75rem 0.5rem 0 0;

        &.sign-button {
            background: $white;
            border-color: $success;
            margin-right: 1rem;

            .sign-check-off {
                top: 0;
                left: -1px;
                border-width: 1px;
            }

            &:hover {
                background: $success;
            }
        }
    }

    &-sub {
        font-size: 1rem;
        display: flex !important;
        align-items: center;
        z-index: 4;
        margin-right: 0.25rem;
        margin-bottom: 0 !important;

        &_location {
            height: 3rem;
        }
    }

    &-detail {
        font-size: 1rem;
        height: 100%;

        &.SystemAdmin-new {
            margin-top: 0;
            max-width: none;
        }

        .blur {
            opacity: 0.5;
        }

        span.block {
            display: block;
            margin-bottom: 0.5rem;
            margin-left: 0.5rem;
        }

        .input-group {
            justify-content: flex-end;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            padding-right: 0.5rem;

            .form-control {
                border-radius: 0.5rem !important;
                margin: 0.25rem 0;
            }

            .form-control:disabled,
            .form-control[readonly] {
                border: 1px solid $light-grey;
                background: $white;
            }
        }

        .dropdown-menu {
            padding: 0;
            transform: translate3d(0, 2.55rem, 0) !important;
            max-height: 10rem;
            overflow-y: scroll;
        }

        .dropdown,
        .dropdown-menu {
            width: 100%;
            flex: 1;

            ul {
                list-style-type: none;
                padding: 0;

                li {
                    border-bottom: 1px solid $light-grey;
                    padding: 0.5rem;

                    svg {
                        margin-right: 1rem;
                    }

                    &:hover {
                        background: $dark-grey;
                        color: $white;
                        cursor: pointer;
                    }
                }
            }
        }

        &_dob {
            // padding-left: 3rem;
            font-size: 1rem;
        }

        &_name {
            // padding-left: 6rem;
            font-size: 1rem;
        }

        &_location {
            // padding-left: 5.5rem;
            font-size: 1rem;
        }

        &_num {
            // padding-left: 7.5rem;
            font-size: 1rem;
        }

        &_num-id {
            font-size: 1rem;
        }

        &_role {
            margin-top: 1rem;

            &-item {
                display: inline-block !important;
                color: $white;
                padding: 0.05rem 0.5rem;
                border-radius: 0.5rem;
                font-weight: bold;
            }

            &-new {
                font-weight: bold;
                display: inline-block !important;
                padding: 0.05rem 0.5rem;
                border-radius: 0.5rem;
                border-width: 2px;
                border-style: solid;
                margin-left: 0.5rem;
                margin-bottom: 0.5rem;

                &:hover {
                    color: $white;
                    cursor: pointer;
                }
            }

            .role-edit {
                &:hover {
                    background: $black;
                    cursor: pointer;
                }
            }
        }

        &_access {
            align-items: center;

            .badge {
                font-size: 0.85rem;
                display: inline-block;
                margin-left: 0.5rem;
                margin-bottom: 0.5rem;
                padding: 0.5rem 0.75rem;
                background: $black;
                color: $white;
            }
        }

        &_sign {
            .sign-button {
                background: $white;
                border-color: $success;

                &:hover {
                    background: $success;
                }

                .sign-check-off {
                    top: 0;
                    left: -1px;
                    border-width: 1px;
                }
            }
        }

        &_deleted {
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            font-weight: bold;
            font-size: 1.5rem;

            p {
                background: $black;
                position: relative;
                padding: 1rem;
                border-radius: 0.5rem;
                z-index: 1;
            }

            span {
                display: flex;
                width: 3rem;
                height: 3rem;
                border-radius: 50rem;
                background: $black;
                align-items: center;
                justify-content: center;
                margin: 1rem auto;
            }
        }
    }

    &-staff {
        span {
            display: block;
        }

        &_name {
            font-weight: bold;
        }

        &_member {
            border: 0;
            margin: 1rem 0 0;
            background: $white;
            border-radius: 0.25rem;
            padding: 0.5rem;
            width: 100%;
            text-align: left;
            font-weight: inherit;

            &:hover {
                color: $white;
                background: $dark-grey;
            }

            span {
                font-size: 1rem;
                display: block;

                &:nth-child(2) {
                    margin-bottom: 0.5rem;
                    margin-top: 0.5rem;
                }
            }
        }

        &_role {
            span {
                display: inline-block;
                border-radius: 0.5rem;
                padding: 0.05rem 0.5rem;
                color: $white;
            }

            &-aocd {
                background: $medium-turquoise;
            }

            &-clinical-area-manger {
                background: $persimmon;
            }

            &-practitioner {
                background: $fuchsia;
            }

            &-associate-practitioner {
                background: $medium-sea-green;
            }

            &-trainee-practitioner {
                background: $bright-yellow-crayola;
            }

            &-pharmacy-staff-member {
                background: $french-violet;
            }

            &-doctor {
                background: $picotee-blue;
            }

            &-messenger {
                background: $french-rose;
            }
        }
    }

    &-role {
        &:hover {
            border-color: $text-body;
        }

        &_aocd {
            border-color: $medium-turquoise;

            &:hover {
                background: $medium-turquoise;
                border-color: $medium-turquoise;
            }
        }

        &_clinical-area-manger {
            border-color: $persimmon;

            &:hover {
                background: $persimmon;
                border-color: $persimmon;
            }
        }

        &_practitioner {
            border-color: $fuchsia;

            &:hover {
                background: $fuchsia;
                border-color: $fuchsia;
            }
        }

        &_associate-practitioner {
            border-color: $medium-sea-green;

            &:hover {
                background: $medium-sea-green;
                border-color: $medium-sea-green;
            }
        }

        &_trainee-practitioner {
            border-color: $bright-yellow-crayola;

            &:hover {
                background: $bright-yellow-crayola;
                border-color: $bright-yellow-crayola;
            }
        }

        &_pharmacy-staff-member {
            border-color: $french-violet;

            &:hover {
                background: $french-violet;
                border-color: $french-violet;
            }
        }

        &_doctor {
            border-color: $picotee-blue;

            &:hover {
                background: $picotee-blue;
                border-color: $picotee-blue;
            }
        }

        &_messenger {
            border-color: $french-rose;

            &:hover {
                background: $french-rose;
                border-color: $french-rose;
            }
        }
    }
}
