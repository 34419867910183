.ProgressBar{
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    max-width: 100%;

    &-half{
        top: -0.3rem;
        left: 0.65rem;
    }
    .svg-circle-bg {
        fill: none;
        stroke: $light-grey;
    }

    .svg-circle {
        fill: none;
    }
}
