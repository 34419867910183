.CDTable {
  .cd {
    &-item-info {
      width: 60%;
    }

    &-fixedWidth:first-child {
      min-width: 1.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    &-detail{
      white-space: pre-line;
    }
  }
  .on-right {
    color: $success;
  }
}
.UomPackSizes {
    margin-top: 4rem; 
}
.QuantityEditTable{
  line-height: 0;
  padding-bottom: 0;
}
.HistoryCDTable {
  margin-bottom: 2rem;
    margin-top: 1rem; 
    border-collapse: inherit;
    border-spacing: 0 6px;
}
