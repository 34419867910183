.AllOrders,
.ReturnOrders,
.SingleOrder {
    .h4 {
        font-weight: bold;
        font-size: 1.25rem;
        margin-top: 0.5rem;
        display: block;
    }

    button.RejectedByWard {
        display: none;
    }

    .location-filter {
        span {
            display: block;
            font-size: 1rem;
            font-weight: bold;
        }
    }

    .icon-group {
        position: relative;
        width: 1rem;
        height: 1rem;
        margin-right: 1rem;
        .icon-1 {
            position: absolute;
            left: 0;
            top: 0;
        }
        .icon-2 {
            position: absolute;
            left: -1px;
            top: -1px;
        }
    }

    .table {
        th {
            width: 7rem;
            &:first-child {
                width: auto;
            }
            &:last-child {
                width: 8rem;
            }
        }
        td {
            .info-items {
                max-width: 60%;
            }
            .comments {
                max-width: 100%;
                flex-shrink: 1;
            }

            &.quantity-unit {
                text-align: left;
            }
        }
    }

    .CheckableInputs {
        justify-content: center;
        margin-top: 1px;

        li:last-child .checkable-label {
            padding-right: 0;
        }

        svg {
            font-size: 1rem;
        }

        .checked-yes svg {
            color: $success;
        }

        .compliant-no svg {
            color: $danger;
        }

        input {
            &:disabled + .checkable-box {
                background-color: unset;
            }
            &:disabled + .checkable-box svg {
                color: $dark-grey;
            }
        }
    }

    span.order-status {
        display: block;
        padding: 0.25rem;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
    }

    .order-number {
        height: 2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50rem;
        margin: 0 auto;
        min-width: 2rem;
        padding: 0 0.5rem;
        text-align: left !important;

        &.action-in {
            border: 1px solid $black;
            color: $black;
        }

        &.action-out {
            background: $black;
            color: $white;
            font-weight: bold;
        }
    }

    .c-switch {
        width: 3.5rem;

        &-slider {
            margin-top: -0.25rem
        }
    }

    .order-status {
        &-amber {
            td:first-child {
                border-color: rgba(236, 155, 0, 1);
            }

            .order-status {
                background: rgba(236, 155, 0, 0.10);
                color: rgba(236, 155, 0, 1);
            }

            .c-switch .c-switch-input:checked+.c-switch-slider {
                background-color: rgba(236, 155, 0, 1);
            }
        }

        &-purple {
            td:first-child {
                border-color: rgba(70, 17, 176, 1);
            }

            .order-status {
                background: rgba(70, 17, 176, 0.10);
                color: rgba(70, 17, 176, 1);
            }

            input {
                border:1px solid rgba(70, 17, 176, 1);
                border-radius: 0.5rem;
            }

            .alert-input {
                border-color: $danger;
            }

            .c-switch .c-switch-input:checked+.c-switch-slider {
                background-color: rgba(70, 17, 176, 1);
            }
        }

        &-green {
            td:first-child {
                border-color: rgba(0, 127, 59, 1);
            }

            .order-status {
                background: rgba(0, 127, 59, 0.10);
                color: rgba(0, 127, 59, 1);
            }

            input {
                border: 1px solid rgba(0, 127, 59, 1);
                border-radius: 0.5rem;
            }

            .c-switch .c-switch-input:checked+.c-switch-slider {
                background-color: rgba(0, 127, 59, 1);
            }
        }

        &-pink {
            td:first-child {
                border-color: #f64a84;
            }

            .order-status {
                background: rgba(246, 74, 132, 0.10);
                color: #f64a84;
            }
            input {
                border: 1px solid #f64a84;
                border-radius: 0.5rem;
            }

            .c-switch .c-switch-input:checked+.c-switch-slider {
                background-color: #f64a84;
            }
        }

        &-black {
            td:first-child {
                border-color: $black;
            }

            .order-status {
                background: rgba(33, 37, 41, 0.10);
                color: $black;
            }

            input {
                border: 1px solid $black;
                border-radius: 0.5rem;
            }

            .c-switch .c-switch-input:checked+.c-switch-slider {
                background-color: $black;
            }
        }

        &-red{
            td:first-child {
                border-color : $danger;
            }

            .order-status {
                background: rgba(246, 74, 132, 0.10);
                color: $danger;
            }
            input {
                border: 1px solid $danger;
                border-radius: 0.5rem;
            }

            .c-switch .c-switch-input:checked+.c-switch-slider {
                background-color: $danger;
            }
        }

        &-light-purple{
            td:first-child {
                border-color : $light-purple;
            }

            .order-status {
                background: rgba(178, 0, 255, 0.10);
                color: $light-purple;
            }
            input {
                border: 1px solid $light-purple;
                border-radius: 0.5rem;
            }

            .c-switch .c-switch-input:checked+.c-switch-slider {
                background-color: $light-purple;
            }
        }
    }

    &-set-title,
    &-set-view {
        padding: 0;
    }

    &-set-view,
    &-set-status {
        .btn {
            text-transform: uppercase;
        }

        .btn-on {
            background: $primary;
            color: $white;
        }
    }

    thead th:first-child {
        padding-left: 0;
    }

    a.order-drug_name {
        color: $text-body;
        width: 100%;
    }

    .order-drug_ref {
        height: 2.25rem;
    }

    .order-drug_name,
    .order-drug_ref {
        display: grid !important;
        align-content: center;
    }

    .order-drug_message {
        position: relative;

        form {
            position: relative;

            textarea {
                resize: none;
            }
        }

        &-input {
            background: $light-grey;
            height: 10rem;
            color: $text-body;
        }

        &-send {
            position: absolute;
            right: 0.5rem;
            bottom: 0.5rem;
        }
    }
    .confirmation-block {
        .label {
            text-align: right;
        }
        .dropdown {
            position: relative;
            width: 100%;
            svg {
                position: absolute;
                right: 0.5rem;
                top: 0.75rem;
            }
            .dropdown-menu {
                padding: 0.15rem 0;
                max-height: 10rem;
                overflow-y: scroll;
                width: 100%;
                top: auto !important;
                bottom: 0;
                transform: translate3d(0px, -34px, 0px) !important;
            }
            ul {
                list-style-type: none;
                padding: 0;
                margin-bottom: 0;
            }
            li {
                background: $white;
                padding: 0.5rem 1rem;
                border-bottom: 1px dotted;
                cursor: pointer;

                &:hover {
                    background: $light-grey;
                    color: $primary;
                }
            }
        }
        .form-check {
            &-label {
                padding-top: 0;
                font-weight: bold;
            }

            &.active-radio {
                .svg-inline--fa {
                    color: #3640B4;
                }
            }
        }
    }
}

.AllOrders,
.ReturnOrders {
    &-pharmacy {
        th:nth-child(2) {
            width: 9rem;
        }
    }
    .show-patient,
    .show-notpatient,
    .show-prescription,
    .show-urgent,
    .show-messages {
        tr.shadow-sm,
        tr.spacer {
            display: none;
        }
    }
    .show-patient {
        tr.filter-patient {
            display: table-row;
        }
    }
    .show-prescription {
        tr.filter-prescription {
            display: table-row;
        }
    }
    .show-notpatient {
        tr.filter-notpatient {
            display: table-row;
        }
    }
    .show-urgent {
        tr.filter-urgent {
            display: table-row;
        }
    }
    .show-messages {
        tr.filter-messages {
            display: table-row;
        }
    }
}

.ReturnOrders {
    .action-block {
        
        background-color: $white-50;
        border: $content-box-border;
        border-radius: $content-box-border-radius;
        margin-bottom: 2rem;
        padding: 1rem;
        
        .label {
            text-align: right;
        }
        .form-check {
            &-label {
                padding-top: 0;
                font-weight: bold;
            }

            &.active-radio {
                .svg-inline--fa {
                    color: #3640B4;
                }
            }
        }

        .required::before {
            color: $danger;
            content: "*";
            font-size: 0.75rem;
            margin-right: 0.2em;
            vertical-align: top;
        }
    }}

  .AllOrders {
    .identity-proof-inputs {
        flex-basis: 200px !important;
    }

    .form {
        margin: 0 1rem -1rem auto;
    }
}

.ReturnOrders {
    th:nth-child(2) {
        width: 6rem;
    }

    .order-status-icon {
        display: flex;
        justify-content: center;

        .fa-check-circle {
            color: $success;
            height: 1.9rem;
            width: 1.9rem;
        }
    }

    .table {
        margin-top: unset;
        th {
            height: 5.5rem;
        }
    }

}

.SingleOrder {
    .table {
        th:last-child {
            width: 8rem;
        }
        td {
            vertical-align: top;
            padding-top: 0.5rem;
            background: none;

            &:first-child {
                &:hover {
                    cursor: default;
                }

                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }

    .order-drug_name {
        font-weight: bold;
        font-size: 1rem;
    }

    .order-drug {
        span {
            display: block;
        }
    }

    .order-drug_loc {
        line-height: calc(2rem + 2px); // To match height of adjacent 'quantity requested' lozenge
    }

    .order-drug_patient {
        margin-top: 1rem;
    }

    span.order-drug_pack {
        display: inline-block;
        margin-right: 0.5rem;
        border-bottom: 1px solid;
    }

    .orderHistory {
        padding-bottom: 2rem;
        &-status {
            border-left: 3px solid $primary;
            padding-left: 1rem;
            span {
                display: block;
            }
        }
    }
}
