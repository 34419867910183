.menu-close-btn,
.menu-open-btn {
    padding: 0.5rem;

    &:focus:not(:focus-visible) {
        box-shadow: unset;
    }
}

.menu-open-btn {
    align-items: center;
    background-color: $primary;
    border: 3px solid $border-25;
    border-radius: 50rem;
    color: $white;
    display: flex;
    font-size: 1.5rem;
    height: 3.15rem;
    justify-content: center;
    left: 30px;
    position: absolute;
    top: 20px;
    width: 3.15rem;
    z-index: 1;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.c-sidebar {
    width: 200px;
    background: none;
    padding: 1rem;
    align-items: center;

    @include media-breakpoint-down(md) {
        background-color: $content-box-background-color;
    }

    &.c-sidebar-fixed {
        @include media-breakpoint-up(lg) {
            z-index: 9;
        }
    }

    .menu-close-btn {
        font-size: 1.5rem;
        margin-left: auto;

        @include media-breakpoint-up(lg) {
            display: none;
        }

        &:hover {
            color: unset;
        }

        svg {
            color: $body-color;
        }
    }

    &-brand {
        margin-top: 1rem;
    }

    .sidebar-logo {
        height: 2rem;
        margin-bottom: 0.85rem;
        margin-top: 0.85rem;
        position: absolute;
        width: 6rem;

        @include media-breakpoint-down(md) {
            left: 30px;
            top: 6px;
        }

        @include media-breakpoint-up(lg) {
            left: 50px;
            top: 1rem;
        }
    }

    .flex-central {
        @include media-breakpoint-down(md) {
            margin-bottom: 3rem;
        }
    }

    .sidebar {
        &-name {
            color: $primary;
            display: none;
            margin-top: 0.5rem;

            @include media-breakpoint-down(md) {
                display: block;
                font-size: 1.25rem;
            }
        }

        &-bumper {
            width: 3.15rem;
            height: 3.15rem;
            border-radius: 50rem;
            border: 1px solid transparent;
            display: flex;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
    }

    svg {
        display: block;
        color: $primary;
        fill: $primary;
    }

    ul {
        max-height: 80vh;
        background-color: $content-box-background-color;
        border: $content-box-border;
        border-radius: 50rem;
        justify-content: space-between;
        width: 6rem;
        padding: 1rem 0;

        @include media-breakpoint-down(md) {
            border: unset;
            border-radius: unset;
            justify-content: unset;
            width: 100%;
        }

        a {
            flex-direction: column;
            font-size: 1.5rem;
            padding: .445rem 1rem;

            svg#Patients_icon #staffcircle {
                fill: $white;
            }

            &.c-active,
            &:hover {
                background: transparent !important;

                .sidebar-bumper {
                    background: $primary;
                    color: $white;
                    border: 3px solid $border-25;
                }

                svg {
                    color: $white;
                    fill: $white;

                    &#Patients_icon #staffcircle {
                        fill: $primary;
                    }
                }
            }
        }
    }
}
