$checkbox-size: 1.5rem;
$checkbox-check-size: 1rem;
$radio-size: 1.375rem;
$legend-font-size: 1.25em;

.CheckableInputs {
    display: flex;
    flex-direction: column;

    &.inline,
    &.inline-legend {
        flex-direction: row;

        .legend {
            padding:
                calc((#{$input-height} - #{$legend-font-size} * #{$line-height-base}) / 2)
                2rem
                calc((#{$input-height} - #{$legend-font-size} * #{$line-height-base}) / 2)
                0;
        }
    }

    &.inline,
    &.inline-inputs {
        li {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }

    .legend span {
        font-size: $legend-font-size;
    }

    ul {
        list-style: none;
        margin: unset;
        padding: unset;
    }

    .checkable-label {
        padding:
            calc((#{$input-height} - #{$checkbox-size}) / 2)
            0.5rem
            calc((#{$input-height} - #{$checkbox-size}) / 2)
            0;

        &.disabled {
            cursor: unset;
        }
    }

    input {
        &[type="checkbox"] {
            + .checkable-box {
                border: $input-border-width solid $input-border-color;
                border-radius: $input-border-radius;
                height: $checkbox-size;
                width: $checkbox-size;
            }

            &:checked + .checkable-box svg {
                opacity: unset;
            }

            &:focus-visible + .checkable-box {
                border-color: $input-focus-border-color;
            }
        }

        &[type="radio"] {
            + .checkable-box {
                border: 0.125rem solid;
                border-radius: 50%;
                height: $radio-size;
                margin: ($checkbox-size - $radio-size) / 2 0;
                width: $radio-size;
            }

            &:checked + .checkable-box {
                border-width: 0.5rem;
            }
        }

        &:focus-visible + .checkable-box {
            box-shadow: $input-focus-box-shadow;
        }

        &:disabled + .checkable-box {
            opacity: $btn-disabled-opacity;
        }
    }

    .checkable-box {
        background-color: $input-bg;
        display: inline-block;
        text-align: center;
        transition-duration: 150ms;
        transition-property: border, box-shadow, opacity;
        vertical-align: top;
    }

    .checkable-text {
        margin-left: 0.5rem;
    }

    svg {
        font-size: $checkbox-check-size;
        margin: calc(#{$checkbox-size - $checkbox-check-size} / 2 - #{$input-border-width});
        opacity: 0;
        transition: opacity 150ms;
    }
}

.disable-witness-toggle {
    font-size: 1rem;
    font-weight: bold;
    margin: -1rem 1rem 2rem auto;
    float: right;
}
.inline-single-row {
    font-size: 0.75rem;
    font-weight: bold;
    margin: -1rem 1rem 2rem auto;
}
